import { render, staticRenderFns } from "./GetApi.vue?vue&type=template&id=423bf8f2&lang=pug&"
import script from "./GetApi.vue?vue&type=script&lang=js&"
export * from "./GetApi.vue?vue&type=script&lang=js&"
import style0 from "./GetApi.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports