import { render, staticRenderFns } from "./Goip.vue?vue&type=template&id=100be71e&scoped=true&lang=pug&"
import script from "./Goip.vue?vue&type=script&lang=js&"
export * from "./Goip.vue?vue&type=script&lang=js&"
import style0 from "./Goip.vue?vue&type=style&index=0&id=100be71e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "100be71e",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QSpinnerFacebook from 'quasar/src/components/spinner/QSpinnerFacebook.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QDialog,QCard,QCardSection,QInput,QSelect,QSpinnerFacebook});
